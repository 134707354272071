import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [CommonModule, HttpClientModule, SharedModule, RouterModule, AngularSvgIconModule],
  exports: [RouterModule, HttpClientModule, HeaderComponent, FooterComponent],
  declarations: [HeaderComponent, FooterComponent],
  providers: []
})
export class CoreModule {}
