import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {catchError, mapTo, tap} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {ToastrService} from 'ngx-toastr';
import {User} from '@app/shared/models/user.interface';
import {AuthResponse, Tokens} from '@app/shared/models/auth.interface';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly USER_DATA = 'USER_DATA';
  loggedUser = new Subject<User>();
  rememberMe: boolean = true;

  login(user: User, rememberMe: boolean): Observable<boolean> {
    this.rememberMe = rememberMe;
    return this.http.post<AuthResponse>(`${environment.apiUrl}api/Token/Authenticate`, user).pipe(
      tap((res: AuthResponse) => {
        if (!!res) {
          const userData = {
            clientId: user.clientId,
            firstName: res.firstName,
            lastName: res.lastName,
            sessionId: res.sessionId,
            displayName: res.displayName
          };
          this.loggedUser.next(userData);
          this.doLoginUser(userData, res.tokens);
        }
      }),
      mapTo(true),
      catchError((error: AuthResponse) => {
        // this.toastr.error(error.errorMessage);
        return of(false);
      })
    );
  }

  logout() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http
      .post<AuthResponse>(
        `${environment.apiUrl}api/Token/Logout`,
        {
          sessionId: this.getSessionId(),
          refreshToken: this.getRefreshToken()
        },
        {headers}
      )
      .pipe(
        tap(() => {
          this.doLogoutUser();
          this.loggedUser.next(null);
        }),
        mapTo(true),
        catchError((error: AuthResponse) => {
          // this.toastr.error(error.errormessage);
          return of(false);
        })
      );
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    const sessionId = this.getSessionId();
    const refreshToken = this.getRefreshToken();

    if (!sessionId || !refreshToken) {
      return of(null);
    }

    return this.http
      .post<AuthResponse>(
        `${environment.apiUrl}api/Token/Refresh`,
        {
          sessionId,
          refreshToken
        },
        {headers}
      )
      .pipe(
        tap((res: AuthResponse) => {
          this.storeTokens(res.tokens);
        })
      );
  }

  getJwtToken() {
    return this.rememberMe ? localStorage.getItem(this.JWT_TOKEN) : sessionStorage.getItem(this.JWT_TOKEN);
  }

  getUserData() {
    return this.rememberMe ? JSON.parse(localStorage.getItem(this.USER_DATA)) : JSON.parse(sessionStorage.getItem(this.USER_DATA));
  }

  clearUserData() {
    this.loggedUser.next(null);
  }

  private doLoginUser(user: User, tokens: Tokens) {
    this.storeTokens(tokens, user);
  }

  private doLogoutUser() {
    this.loggedUser.next(null);
    this.removeTokens();
  }

  private getRefreshToken() {
    return this.rememberMe ? localStorage.getItem(this.REFRESH_TOKEN) : sessionStorage.getItem(this.REFRESH_TOKEN);
  }

  private getSessionId() {
    const userData = this.rememberMe ? JSON.parse(localStorage.getItem(this.USER_DATA)) : JSON.parse(sessionStorage.getItem(this.USER_DATA));

    return !!userData ? userData.sessionId : null;
  }

  private storeJwtToken(jwt: string) {
    this.rememberMe ? localStorage.setItem(this.JWT_TOKEN, jwt) : sessionStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens, userData?: User) {
    if (!tokens) {
      return;
    }
    if (this.rememberMe) {
      localStorage.removeItem(this.JWT_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
      localStorage.setItem(this.JWT_TOKEN, tokens.jwt);
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
      if (!!userData) {
        localStorage.setItem(this.USER_DATA, JSON.stringify(userData));
      }
    }

    if (!this.rememberMe) {
      sessionStorage.removeItem(this.JWT_TOKEN);
      sessionStorage.removeItem(this.REFRESH_TOKEN);
      sessionStorage.setItem(this.JWT_TOKEN, tokens.jwt);
      sessionStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
      if (!!userData) {
        sessionStorage.setItem(this.USER_DATA, JSON.stringify(userData));
      }
    }
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_DATA);
    sessionStorage.removeItem(this.JWT_TOKEN);
    sessionStorage.removeItem(this.REFRESH_TOKEN);
    sessionStorage.removeItem(this.USER_DATA);
  }
}
