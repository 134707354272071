import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  faUserInjured,
  faAngleRight,
  faAngleLeft,
  faPlus,
  faSearch,
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
  faSignOutAlt,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faUserInjured,
  faAngleRight,
  faAngleLeft,
  faPlus,
  faSearch,
  faMinusCircle,
  faPlusCircle,
  faTimesCircle,
  faSignOutAlt,
  faCalendarCheck
);

@NgModule({
  imports: [CommonModule, FormsModule, FontAwesomeModule, NgbModule, AngularSvgIconModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule]
})
export class SharedModule {}
