import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/shared/models/user.interface';
import { AuthService } from '@app/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'sk-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: User;
  subscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.subscription = this.authService.loggedUser.subscribe(user => {
      if (!!user) {
        this.currentUser = user;
      }
    });

    const userData = this.authService.getUserData();
    this.currentUser = !!userData ? userData : null;
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.currentUser = null;
      this.authService.loggedUser.next(null);
      this.toastr.success('You have successfully logged out.');
      this.router.navigateByUrl('login');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
