import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { RouterModule } from '@angular/router';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AuthGuard } from './auth-guard/auth-admin.guard';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from '@app/shared/interceptors/jwt.interceptor';

const loadingConfig = {
  animationType: ngxLoadingAnimationTypes.threeBounce,
  backdropBackgroundColour: 'rgba(0,0,0,0.3)',
  backdropBorderRadius: '0px',
  primaryColour: '#ffffff',
  secondaryColour: '#ffffff',
  tertiaryColour: '#ffffff'
};

@NgModule({
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  imports: [
    AuthRoutingModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    NgxLoadingModule.forRoot(loadingConfig)
  ],
  declarations: [AuthRoutingModule.components]
})
export class AuthModule {}
