import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sk-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
  formGroup: FormGroup;
  clientIdLength: boolean = false;
  loading: boolean = false;

  get clientId() {
    return this.formGroup.get('clientId');
  }
  get username() {
    return this.formGroup.get('username');
  }
  get password() {
    return this.formGroup.get('password');
  }
  get rememberMe() {
    return this.formGroup.get('rememberMe');
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('home');
      return;
    }
    this.formGroup = this.fb.group({
      clientId: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      username: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100)
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100)
        ])
      ],
      rememberMe: [
        false
      ]
    });

    this.formGroup.get('clientId').valueChanges.subscribe(value => {
      this.clientIdLength = (value + '').length >= 6;
    });
  }

  login() {
    this.loading = true;
    this.authService
      .login(this.formGroup.value, this.rememberMe.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          if (!!data) {
            this.toastr.success('You have successfully logged in!');
            this.router.navigate(['/home']);
          } else {
            this.toastr.error(
              `Incorect client core, username or password.
              Please check your credentials and try again.`
            );
          }
        },
        error => {
          this.loading = false;
          if (!!error) {
            this.toastr.error(
              `Incorect client core, username or password.
              Please check your credentials and try again.`
            );
          }
        }
      );
  }
}
